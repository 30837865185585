import Footer from "./Footer";
import HeadBar from "./HeadBar";
import Header from "./Header";
import MainGallery from "./MainGallery";

export default function Home (props) {
    return (
        <div>
            <HeadBar />
            <Header />
            <MainGallery db={props.db}/>
            <Footer />
        </div>
    );
}