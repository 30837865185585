import { Link } from "react-router-dom";

export default function PropetyCard(props){

    const divStyle = (src) => ({
        backgroundImage: 'url(' + src + ')'
      })

    return (
        <Link 
            style={divStyle(props.data.thumbimg)} className="maingallery-card" 
            to={`propiedades/${props.data.id}`} >
            <div>
                <div className="maingallery-card__short maingallery-card__short1">
                    <p>{props.data.short1}</p>
                </div>
            </div>

            <div>
                <div 
                    className={ props.data.transaction === "ALQUILER" ? "transactionA transac" : "transactionV transac" 
                }>
                    <p>{props.data.transaction}</p>
                </div>
            </div>
        </Link>
    );
}