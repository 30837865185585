import { Link } from "react-router-dom";
import "../App.scss"

export default function Header (){
    return (
        <nav className="main-nav">
            <Link to="/">
                <img src="../img/logo.jpg" />
            </Link>

            <Link className="main-nav__link" to="/">
                Inicio
            </Link>
        </nav>
    )
}