import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import PropertyDetail from "./PropertyDetail";

export default function App() {
    
    const [db, setDB] = useState([]);

    useEffect(()=>{
    async function getData() {
        const res = await fetch("../propiedades.json");
        const data = await res.json();
        setDB(data);
    }

    getData();
    
    }, []);

    return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home db={db} />} />
      <Route path="propiedades/:propertyId" element={<PropertyDetail db={db}/>} />

      <Route
      path="*"
      element={
        <main>
          <p>Dirección equivocada!</p>
        </main>
      }
    ></Route>
    </Routes>
  </BrowserRouter>
}