export default function PropertyHeader(props){
    return (
        <div className="property-header">
            <div className={ props.cont.transaction === "ALQUILER" ? "transactionA transac" : "transactionV transac" }>
                <p>{props.cont.transaction}</p>
            </div>

            <h1>{props.cont.short1}</h1>
            <h2>{props.cont.currency} {props.cont.price} </h2>
            <h3>+{props.cont.expensas} {props.cont.expensascurrency}</h3>
            <h4>{props.cont.description}</h4>
            <p>{props.cont.short2}</p>
        </div>
    );
}