import { Component } from "react";
import { Link } from "react-router-dom";
import ContactShort from "./ContactShort";
import Socials from "./Socials";

class Footer extends Component {
    render(){
        return (
            <div className="foot-body">
                <div className="foot-nav">
                    <Link className="foot-nav__link" to="/">
                        <div className="foot-nav__link-content">
                            <img src="../img/wpicon.png" />
                        </div>
                    </Link>
                    <Link className="foot-nav__link" to="/">
                        <div className="foot-nav__link-content">
                            <img src="../img/ig.png" />
                        </div>
                    </Link>
                    <Link className="foot-nav__link" to="/">
                        <div className="foot-nav__link-content">
                            <img src="../img/fb.png" />
                        </div>
                    </Link>
                    <Link className="foot-nav__link" to="/">
                        <div className="foot-nav__link-content">
                            <img src="../img/zprop2.png" />
                        </div>
                    </Link>
                    <Link className="foot-nav__link" to="/">
                        <div className="foot-nav__link-content">
                            <img src="../img/logocucicba.png" />
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Footer;