import Gallery from "react-grid-gallery";
import PropertyHeader from "./PropertyHeader";

export default function PropertyBody(props){
    return (
        <div className="property-body_inside">

            <PropertyHeader cont={props.cont} />

            <Gallery images={props.images} />

            <h1>{props.cont.headline}</h1>
            <p>{props.cont.longdesc}</p>
        </div>
    );
}