import Gallery from "react-grid-gallery";
import { useParams } from "react-router-dom";
import PropertyBody from "./PropertyBody";
import PropertyHeader from "./PropertyHeader";

export default function PropertyGallery(props) {
    const db = props.db;
    let aptimgs = [];
    let params = useParams();
    let apartment = db.find((depto) => depto.id === params.propertyId);

    function getImgs(){
        apartment.imgs.map((img)=> aptimgs.push({ src: img[1], thumbnail: img[0], thumbnailWidth: img[2], thumbnailHeight: img[3] }));
        return aptimgs;
    }

    return(
        <div className="property-body">
            {apartment && <PropertyBody cont={apartment} images={getImgs()} />}
        </div>
    );
}