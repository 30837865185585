import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Property from "./routes/property";
import Properties from "./routes/properties";
import Home from "./components/App";
import App from "./components/App";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <App />
);